import updateSize from './updateSize.js';
import updateSlides from './updateSlides.js';
import updateAutoHeight from './updateAutoHeight.js';
import updateSlidesOffset from './updateSlidesOffset.js';
import updateSlidesProgress from './updateSlidesProgress.js';
import updateProgress from './updateProgress.js';
import updateSlidesClasses from './updateSlidesClasses.js';
import updateActiveIndex from './updateActiveIndex.js';
import updateClickedSlide from './updateClickedSlide.js';

export default {
  updateSize,
  updateSlides,
  updateAutoHeight,
  updateSlidesOffset,
  updateSlidesProgress,
  updateProgress,
  updateSlidesClasses,
  updateActiveIndex,
  updateClickedSlide,
};
